export default (subject, link, file, alt_text) => `<!DOCTYPE html>
<html lang="tr">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>${subject}</title>
</head>

<body>
<div style="display: none; max-height: 0px; overflow: hidden;">
${alt_text}
</div>
    <table style="text-align:center;background-color:white" width="100%">
        <tbody>
            <tr>
                <td width="640px" align="center">
                    <table style="max-width:640px;margin:0 auto" width="100%">
                        <tbody>
                            <tr>
                                <td>
                                    <a href="${link}" alt="" target="_blank">
                                        <img alt="" src="http://rotaileshop.com/images/products/${file}"
                                            width="100%">
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    </table>

    <p><img src=\"https://rotaileshop.com/og-logo.jpg\" width=\"100\"></p><p>RotaileShop'a ulaşabileceğiniz kanallar;</p><p>Twitter : <a href=\"https://twitter.com/rotaileshop\">Rotaileshop</a><br>Instagram : <a href=\"https://instagram.com/rotaileshop\">Rotaileshop</a><br>Site : <a href=\"https://rotaileshop.com\">www.rotaileshop.com</a><br>Whatsapp : 0 (544) 387 2020</p>
</body>

</html>`;
