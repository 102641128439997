<template>
    <div>
        <CRow>
            <CCol col="12">
                <CCard>
                    <CCardHeader>E-Posta Gönder</CCardHeader>
                    <CCardBody>
                        <CForm>
                            <CButton
                                type="button"
                                size="sm"
                                color="primary"
                                v-on:click="$refs.fileInput.click()"
                            >
                                <CIcon name="cil-cloud-upload"/> Fotoğraf Yükle
                            </CButton>
                            <input type="file" class="hide" ref="fileInput" @change="fileSelected($event)" />

                            <CInput
                                label="Mail Konusu"
                                horizontal
                                :value.sync="mailing.subject"
                            />
                            <CInput
                                label="Mail Link"
                                horizontal
                                :value.sync="mailing.link"
                            />
                            <CInput
                                label="Mail Alt Metni"
                                horizontal
                                :value.sync="mailing.alt_text"
                            />
                            <CSelect
                                label="Şehir"
                                horizontal
                                :value.sync="mailing.city"
                                :options="cities"
                                placeholder="Seçim yapınız"
                            />
                            <CRow form class="form-group">
                                <CCol tag="label" sm="3" class="col-form-label">
                                    Test Gönderimi
                                </CCol>
                                <CCol sm="9">
                                    <CSwitch
                                        class="mr-1"
                                        color="primary"
                                        :checked.sync="mailing.isForTest"
                                    />
                                </CCol>
                            </CRow>
                        </CForm>
                    </CCardBody>
                    <CCardFooter>
                        <CButton
                            type="button"
                            size="sm"
                            color="primary"
                            v-on:click="save()"
                        >
                            <CIcon name="cil-check-circle"/> Kaydet
                        </CButton>
                    </CCardFooter>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
import axios from 'axios';
import { VueEditor } from 'vue2-editor';
import cities from '../../utils/settings/cities';
import getMailContent from '../../utils/settings/getMailContent';


export default {
    name: 'MailSend',

    components: {
        VueEditor
    },

    data () {
        return {
            mailing: {
                subject: '',
                link: '',
		alt_text: '',
                content: '',
                city: '',
                isForTest: false
            },
            mailTemplates: [],
            cities,
            alert: {
                isOpen: false,
                color: '',
                message: ''
            },
            uploadProgress: 0,
            isUploading: false,
            fileName: ''
        }
    },

    methods: {
        closeAlert() { this.alert.isOpen = false; },

        async save() {
            try {
                if (this.fileName.length === 0) {
                    alert('Lütfen fotograf seçiniz!');
                    return
                }
                const response = await axios({
                    method: 'post',
                    url: '/mail/send',
                    data: {
                        subject: this.mailing.subject,
                        content: getMailContent(this.mailing.subject, this.mailing.link, this.fileName, this.mailing.alt_text),
                        city: this.mailing.city && this.mailing.city.length > 0 ? this.mailing.city : null,
                        isForTest: this.mailing.isForTest
                    }
                });

                if (response.status === 201) {
                    alert('Mail gönderimi başlatıldı!')
                }
            } catch (error) {
                console.log(error)
                this.alert = {
                    isOpen: true,
                    color: 'danger',
                    message: 'İşlem gerçekleştirilirken bir hata oluştu'
                };
            }
        },

        async fileSelected(event) {
            const [file] = event.target.files;
            const data = new FormData()
            data.append('file', file);
            this.isUploading = true;
            try {
                const response = await axios({
                    url: `/mail/file`,
                    method: 'post',
                    data,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (progress) => {
                        this.uploadProgress = Math.round((100 * progress.loaded) / progress.total);
                    }
                });

                this.fileName = response.data.fileName;
            } catch (error) {
                console.error(error);
                alert('Dosya yüklenirken bir hata oluştu');
            }

            this.isUploading = false;
            this.uploadProgress = 0;
        },

    },

    async mounted() {
        const response = await axios({
            method: 'get',
            url: '/mail/templates'
        });
        this.mailTemplates = response.data.results.map(x => ({
            label: x.name,
            value: x._id
        }));
    }
}
</script>

<style lang="scss" scoped>
label {
    margin-bottom: 15px;
}
</style>
